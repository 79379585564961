import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Button,
  Divider,
  HStack,
  IconButton,
  Image,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DeleteIcon } from '@chakra-ui/icons';
import { Toaster } from 'react-hot-toast';

import EnvContext from '../../../context/EnvContext';
import CourseContext from '../../../context/CourseListContext';
import AuthContext from '../../../context/AuthContext';
import SyllabusContext from '../../../context/SyllabusContext';
import { VideoContext } from '../../../context/VendorContext/VideoContext';

import { DeleteCourse } from '../../../components/alert/DeleteCourse';
import CourseShowcase from '../course/CourseShowcase';

const CourseHome = React.memo(() => {
  const { backendUrl } = useContext(EnvContext);
  const { courses, fetchData, setCourseId, courseId, getCourse, courseData } =
    useContext(CourseContext);
  const {
    handleAssignedVideo,
    handleGetAssignedVideoByAdmin,
    handleDeleteAssignedVideo,
  } = useContext(VideoContext);
  const { getSyllabus } = useContext(SyllabusContext);
  const { vendorCourseData, handleUserData } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [index, setIndex] = useState(null);

  const role = localStorage.getItem('role');
  const userId = localStorage.getItem('userId');
  const vendorId = useMemo(
    () => new URLSearchParams(location.search).get('vendorId'),
    [location]
  );

  // Redirect unauthorized users
  useEffect(() => {
    if (role !== 'admin' && role !== 'vendor') {
      navigate('/dashboard');
    }
  }, [role, navigate]);

  // Fetch initial data
  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      try {
        if (role === 'vendor') {
          await handleUserData();
          await handleGetAssignedVideoByAdmin(userId);
        }
        await fetchData();
      } catch (err) {
        setError('Failed to fetch courses.');
      } finally {
        setLoading(false);
      }
    };

    fetchDataAsync();
  }, [role, userId]);

  // Fetch syllabus and course details
  useEffect(() => {
    if (courseId) {
      getCourse();
    }
  }, [courseId]);

  useEffect(() => {
    if (courseData.syllabus) {
      getSyllabus(courseData.syllabus._id);
    }
  }, [courseData]);

  const courseList = useMemo(
    () => (role === 'vendor' ? vendorCourseData : courses),
    [role, vendorCourseData, courses]
  );

  const handleCourseClick = useCallback(
    (id, i) => {
      setCourseId(id);
      setIndex(i);
    },
    [setCourseId]
  );

  const renderAdminActions = useCallback(
    course => (
      <>
        {vendorId && !course.assignedTo?.includes(vendorId) && (
          <Button
            onClick={() =>
              handleAssignedVideo({ userId: vendorId, videoId: course._id })
            }
          >
            Assign
          </Button>
        )}
        {vendorId && course.assignedTo?.includes(vendorId) && (
          <Button
            onClick={() =>
              handleDeleteAssignedVideo({
                userId: vendorId,
                videoId: course._id,
              })
            }
          >
            Revoke
          </Button>
        )}
      </>
    ),
    [vendorId, handleAssignedVideo, handleDeleteAssignedVideo]
  );

  if (loading) {
    return (
      <VStack h="100vh" justifyContent="center">
        <Spinner size="xl" />
      </VStack>
    );
  }

  if (error) {
    return (
      <VStack h="100vh" justifyContent="center">
        <p>{error}</p>
      </VStack>
    );
  }

  return (
    <HStack w="100%" h="100vh" alignItems="flex-start" px="5rem">
      <VStack w="60%" alignItems="center" justifyContent="center" spacing={2}>
        {courseList?.length > 0 ? (
          courseList.map((course, i) => (
            <React.Fragment key={course._id}>
              <HStack
                p={1}
                justifyContent="space-between"
                onClick={() => handleCourseClick(course._id, i)}
                cursor="pointer"
                bg={i === index ? 'blue.100' : 'white'}
                w="100%"
              >
                <HStack>
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src={`${backendUrl}${course.banner}`}
                    alt={course.courseName}
                  />
                  <p>{course.courseName}</p>
                </HStack>
                <HStack spacing={3}>
                  {role === 'admin' && renderAdminActions(course)}

                  {role === 'vendor' || role === 'admin' ? (
                    <>
                      <Button colorScheme="orange">
                        <Link to={`/${role}/manage_content`}>
                          Manage Contents
                        </Link>
                      </Button>
                      <Button colorScheme="orange">
                        <Link to={`/${role}/manage_projects`}>
                          Manage Projects
                        </Link>
                      </Button>
                    </>
                  ) : null}

                  <DeleteCourse courseId={course._id}>
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Delete"
                      colorScheme="red"
                    />
                  </DeleteCourse>
                </HStack>
              </HStack>
              {i !== courseList.length - 1 && (
                <Divider orientation="horizontal" />
              )}
            </React.Fragment>
          ))
        ) : (
          <h1>No courses available</h1>
        )}
      </VStack>

      <Divider orientation="vertical" />

      <VStack w="40%" alignItems="flex-start">
        <Tabs w="100%">
          <TabList>
            <Tab>About Course</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <CourseShowcase index={index} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>

      <Toaster />
    </HStack>
  );
});

export default CourseHome;
