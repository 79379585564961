import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { toast } from 'react-toastify';

import EnvContext from '../EnvContext';

export const VendorContext = createContext();

const VendorContextProvider = ({ children }) => {
  //   const { token } = useContext(AuthContext);
  // const token = localStorage.getItem('token');
  const [token, setToken] = useState(() => localStorage.getItem('token'));

  const { backendUrl } = useContext(EnvContext);

  const [vendorData, setVendorData] = useState([]);

  const [vendorDetailsAdmin, setVendorDetailsAdmin] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [vendorUser, setVendorUser] = useState([]);

  const headers = {
    authorization: `Bearer ${token}`,
  };

  const handleGetVendor = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`${backendUrl}/vendors`, { headers });

      if (response.status === 200) {
        const data = response.data;
        const vendors = data.vendors;

        toast.success(data.message);

        if (JSON.stringify(vendors) !== JSON.stringify(vendorData)) {
          setVendorData(vendors);
        } else {
          console.log('No changes in vendor data. Skipping update');
        }
      } else {
        toast.error('Something Went wrong');
      }
    } catch (error) {
      console.error('Error fetching vendors:', error);
      toast.error('Failed to fetch vendors');
    } finally {
      setIsLoading(false);
    }
  };

  // Add vendor
  const handlePostVendor = async values => {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };
      await axios.post(`${backendUrl}/vendors`, values, {
        headers,
      });

      //   socketServices.emitAddVendor(values);
      toast.success('Data Successfully Submit');
      handleGetVendor();
    } catch (error) {
      console.log(error);
      toast.error(`${error.response?.data?.message || 'Error adding vendor'}`);
    }
    // } finally {
    //   toast.dismiss();
    // }
  };

  // Listen for vendor updates from socket.IO
  //   useEffect(() => {
  //     socketServices.onUpdateVendors(updatedVendor => [
  //       setVendorData(updatedVendor),
  //     ]);

  //     return () => {
  //       socketServices.disconnect(); // Clean up Socket.IO connection
  //     };
  //   }, []);

  const handleDeleteVendor = async vendorid => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this vendor?'
    );

    if (!confirmDelete) {
      return;
    }

    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };
      const response = await axios.delete(`${backendUrl}/vendors/${vendorid}`, {
        headers,
      });

      const data = response.data;
      toast.success(data.message);

      handleGetVendor();
    } catch (error) {
      console.error(error);
      const errorMessage =
        error.response?.data?.message ||
        'An error occurred while deleting the vendor.';
      toast.error(errorMessage);
    }
  };

  const handleUpdateVendor = async (updatedData, vendorId) => {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };

      await axios
        .put(`${backendUrl}/vendors/${vendorId}`, updatedData, {
          headers,
        })
        .then(res => toast.success(res.data.message));
    } catch (error) {
      console.error(error);
      toast.error('Error updating vendor');
    }
  };

  const handleCreateVendor = async data => {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${backendUrl}/vendors/solp/admin`,
        data,
        { headers }
      );

      if (response.status === 201) {
        toast.success(response?.data?.message);
        // setVendorDetails(response?.data?.newVendor);
      }
    } catch (error) {
      console.error('Error creating vendor', error);
    }
  };

  const handleGetVendorAdmin = async () => {
    setIsLoading(true);
    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${backendUrl}/vendors/solp/admin`, {
        headers,
      });

      if (response?.status === 200) {
        setVendorDetailsAdmin(response?.data?.vendorAdmin);
        toast.success(`${response?.data?.message}`);
      }
    } catch (error) {
      console.error('Error Fetching VendorAdmin', error);
      toast.error(`${error.message}` || 'Error fetching vendorAdmin');
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleBlockVendor = async userId => {
    if (!userId) {
      toast.error('Invalid User IDs! Please try again.');
      return;
    }
    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${backendUrl}/vendors/solp/admin/block/${userId}`,
        null,
        { headers }
      );

      handleGetVendorAdmin();

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
      }
    } catch (error) {
      console.error('Error block or unblock vendor', error);
    }
  };

  const handlePermanentDeleteVendor = async userId => {
    if (!userId) {
      toast.error('Invalid User IDs! Please try again.');
      return;
    }

    try {
      const response = await axios.delete(
        `${backendUrl}/vendors/solp/admin/delete/${userId}`,
        { headers }
      );

      if (response.status === 200) {
        toast.success(`${response?.data?.message}`);
        handleGetVendorAdmin();
      }
    } catch (error) {
      console.error('Error permanenlty delete vendor', error);
      toast.error(`${error.message}` || 'Error permanenlty delete vendor');
    }
  };

  const handleCreateUserByVendor = async (userId, values) => {
    if (!userId || !values) {
      toast.error('Invalid User IDs and Values! Please try again.');
      return;
    }

    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${backendUrl}/vendors/solp/admin-user?userId=${userId}`,
        values,
        { headers }
      );

      if (response.status === 201) {
        toast.success(`${response.data.message}`);
      }

      handleGetUserByVendor(userId);
    } catch (error) {
      console.log('Error Creating user');
      toast.error(`${error.message}` || 'Error Creating User');
    }
  };

  const handleGetUserByVendor = useCallback(async userId => {
    if (!userId) {
      toast.error('Invalid User IDs! Please try again.');
      return;
    }
    let role = localStorage.getItem('role')
    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${backendUrl}/vendors/solp/admin-user?userId=${userId}`,
        { headers }
      );
      console.log("role", role);
      
      if (response.status === 200) {
        toast.success(`${response?.data?.message}`);
        setVendorUser(response?.data?.vendorUser);
      }
    } catch (error) {
      console.error('Error fetch user vendor', error);
      toast.error(`${error.message}` || 'Fetched user');
    }
  }, []);

  const handleToggleBlockVendorUser = async (userId, vendorUserId) => {
    if (!userId || !vendorUserId) {
      toast.error('Invalid User IDs! Please try again.');
      return;
    }

    try {
      const headers = {
        authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${backendUrl}/vendors/solp/admin-user/block?userId=${userId}&vendorUserId=${vendorUserId}`,
        null,
        { headers }
      );

      if (response.status === 201) {
        toast.success(`${response?.data?.message}` || 'Done');
      } else {
        toast.warning(`Unexpected response: ${response?.status}`);
      }

      handleGetUserByVendor(userId);
    } catch (error) {
      console.error('Error block and unblock user! Try again..');
      toast.error('Error block and unblock user! Try again..');
    }
  };

  const memoizedVendorData = useMemo(() => vendorData, [vendorData]);

  const memoizedVendorAdmin = useMemo(
    () => vendorDetailsAdmin,
    [vendorDetailsAdmin]
  );

  const memoizedVendorUser = useMemo(() => vendorUser, [vendorUser]);

  const contextValue = useMemo(
    () => ({
      handleGetVendor,
      handlePostVendor,
      handleDeleteVendor,
      handleUpdateVendor,
      handlePermanentDeleteVendor,
      handleToggleBlockVendor,
      handleCreateVendor,
      handleGetVendorAdmin,
      handleCreateUserByVendor,
      handleGetUserByVendor,
      handleToggleBlockVendorUser,
      vendorData: memoizedVendorData,
      vendorDetails: memoizedVendorAdmin,
      vendorUser: memoizedVendorUser,
      isLoading,
    }),
    [
      handleGetVendor,
      handlePostVendor,
      handleDeleteVendor,
      handleUpdateVendor,
      handlePermanentDeleteVendor,
      handleToggleBlockVendor,
      handleCreateVendor,
      handleGetVendorAdmin,
      handleCreateUserByVendor,
      handleGetUserByVendor,
      handleToggleBlockVendorUser,
      memoizedVendorAdmin,
      memoizedVendorData,
      memoizedVendorUser,
      isLoading,
    ]
  );

  return (
    <VendorContext.Provider value={contextValue}>
      {children}
    </VendorContext.Provider>
  );
};

export default VendorContextProvider;
