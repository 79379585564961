import React, { Suspense, useEffect } from 'react';

import {
  ChevronRight,
  BookOpen,
  Clock,
  Users,
  Trophy,
  ArrowRight,
} from 'lucide-react';

import studentwithlaptop from '../../../assets/images/studentwithlaptop.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Carroussel } from '../../../components/others/Carroussel';
import useSaveLastRoute from '../../../hooks/useSaveLastRoute';
import Footer from '../../../components/layouts/Footer';
// import CourseCategories from '../../../components/elements/Courses/CourseCategories';
// import CourseHighlights from '../../../components/elements/Courses/CourseHighlight';

// Lazy loading
const CourseHighlights = React.lazy(() =>
  import('../../../components/elements/Courses/CourseHighlight')
);
const CourseCategories = React.lazy(() =>
  import('../../../components/elements/Courses/CourseCategories')
);

export const cryptokey = 'asdfghjkl';

const Home = () => {
  useSaveLastRoute();
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Hero Section */}
      <div className="relative bg-gray-900 py-20">
        <div className="max-w-7xl mx-auto px-6">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-8">
              <h1 className="text-5xl font-bold text-white leading-tight">
                Master&nbsp;
                <span className="text-blue-400">Creative Skills&nbsp; </span>
                and Launch Your Career Now!!
              </h1>

              <p className="text-xl text-gray-300">
                Unlock your potential. Discover a world of knowledge. Start your
                journey today with over 100+ courses to choose from.
              </p>

              <div className="flex space-x-4">
                <button className="px-8 py-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                  Get Started
                </button>
                <button className="px-8 py-4 text-white border border-white rounded-lg hover:bg-white/10 flex items-center">
                  Learn More <ChevronRight className="ml-2" />
                </button>
              </div>

              <div className="flex items-center space-x-12 text-white">
                <div className="text-center">
                  <div className="text-3xl font-bold">100K+</div>
                  <div className="text-gray-400">Active Users</div>
                </div>
                <div className="text-center">
                  <div className="text-3xl font-bold">500+</div>
                  <div className="text-gray-400">Video Courses</div>
                </div>
                <div className="text-center">
                  <div className="text-3xl font-bold">1M+</div>
                  <div className="text-gray-400">Students</div>
                </div>
              </div>
            </div>

            <div className="relative">
              <img
                src={studentwithlaptop}
                alt="Student learning"
                className="rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-20 bg-gray-100">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">
              Why Choose Step of Learning Process?
            </h2>
            <p className="text-xl text-gray-600">
              Experience the best online learning platform
            </p>
          </div>

          <div className="grid md:grid-cols-4 gap-8">
            {[
              {
                icon: <BookOpen className="w-8 h-8 text-blue-600" />,
                title: 'Expert Instructors',
                description:
                  'Learn from industry experts who are passionate about teaching',
              },
              {
                icon: <Clock className="w-8 h-8 text-blue-600" />,
                title: 'Flexible Learning',
                description:
                  'Study at your own pace with lifetime access to courses',
              },
              {
                icon: <Users className="w-8 h-8 text-blue-600" />,
                title: 'Community Support',
                description:
                  'Join a community of learners and get support when needed',
              },
              {
                icon: <Trophy className="w-8 h-8 text-blue-600" />,
                title: 'Certification',
                description:
                  'Earn recognized certificates upon course completion',
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="p-6 bg-gray-50 rounded-lg hover:shadow-lg transition-shadow"
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <CourseCategories />
          <CourseHighlights />
        </Suspense>
      </div>

      {/* Popular Courses Section */}
      <div className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex justify-between items-center mb-12">
            <div>
              <h2 className="text-4xl font-bold mb-4">Popular Courses</h2>
              <p className="text-xl text-gray-600">
                Explore our most sought-after courses
              </p>
            </div>
            <button
              onClick={() => navigate('/products')}
              className="flex items-center text-blue-600 hover:text-blue-700"
            >
              View All Courses <ArrowRight className="ml-2" />
            </button>
          </div>

          <Carroussel />
        </div>
      </div>

      {/* Call to Action */}
      <div className="bg-blue-400 text-white py-20">
        <div className="max-w-7xl mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-6">
            Ready to Start Your Learning Journey?
          </h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Join millions of learners already learning on MasterMind. Start your
            journey today!
          </p>
          <button className="px-8 py-4 bg-white text-blue-600 rounded-lg hover:bg-gray-100 font-semibold">
            Get Started Now
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
