import {
  Box,
  Heading,
  Flex,
  List,
  ListItem,
  Link,
  Text,
  Image,
  Link as ChakraLink
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useCourse } from '../../context/CourseListContext';
import { globe, logo } from '../../assets/images';

const Footer = () => {
  const { topCourses} = useCourse();

  return (
    <>
      <Box
        as="footer"
        bg="#cbd5e087"
        borderTop="1px solid"
        borderColor="gray.300"
        py="2.5rem"
        fontSize="0.875rem"
        position="relative"
      >
        <Box
          maxW="64rem"
          marginX="auto"
          pb="2rem"
          mb="1.5rem"
          px={10}
          borderBottom="1px solid"
          borderColor="gray.300"
        >
          <Flex
            flexWrap="wrap"
            alignItems="start"
            justifyContent="space-between"
          >
            <Box
              w={{ base: '100%', sm: '50%', md: 'max-content' }}
              mb={{ base: '1.5rem', lg: '0' }}
            >
              <Heading
                as="h5"
                color="gray.700"
                mb="0.5rem"
                fontSize="0.875rem"
                fontWeight="600"
              >
                STEPS OF LEARNING PROCESS
              </Heading>
              <List lineHeight="2" justifyContent="center">
                <LinkItem text="Contact Us" link="/contact_us" />
                <LinkItem text="About Us" link="/about_us" />
                <LinkItem text="Services" link="/services" />
                <ChakraLink 
            fontWeight="600"
            color="rgba(113, 128, 150, 1)"
            _hover={{ color: 'green.600' }}
                 href='https://forms.gle/KiFK8j5RKJpAT4k8A' isExternal>Report</ChakraLink>
              </List>
            </Box>
            <Box
              w={{ base: '100%', sm: '50%', md: 'max-content' }}
              mb={{ base: '1.5rem', lg: '0' }}
            >
              <Heading
                as="h5"
                color="gray.700"
                mb="0.5rem"
                fontSize="0.875rem"
                fontWeight="600"
              >
               Our Top Courses
              </Heading>
              <List lineHeight="2">
                {topCourses &&
                  topCourses.map((course, i) => (
                    <LinkItem
                      key={i}
                      text={course.courseName}
                      link={`/indi_course/${course._id}`}
                      courseId={course._id}
                    />
                  ))}
              </List>
            </Box>
            <Box
              w={{ base: '100%', sm: '50%', md: 'max-content' }}
              mb={{ base: '1.5rem', lg: '0' }}
            >
              <List lineHeight="2">
                <LinkItem
                  text="Terms & Conditions"
                  link="/terms_n_conditions"
                />
                <LinkItem text="Privacy Policy" link="/privacy_policy" />
                <LinkItem
                  text="Cancellation & Refund"
                  link="/cancellation_n_refund"
                />
              </List>
            </Box>
          </Flex>
        </Box>
        <Flex maxW="64rem" mx="auto" alignItems="center" paddingInlineStart={10}>
        <Image src={logo} style={{ width: '1.25rem', height: '1.25rem' }}/>
          <Text color="gray.600" fontSize="0.875rem" pl="0.5rem">
            &copy; 2019 company, Inc. All rights reserved.
          </Text>
        </Flex>
        <Image
          position="absolute"
          top="0"
          left="0"
            src={globe}
            alt="Background Globe Image"
            objectFit="cover"
            width="100%"
            height="100%"
          zIndex="-1"
          opacity={0.13}
          />
      </Box>
    </>
  );
};

const LinkItem = ({ text, isTag = false, tagText, link, courseId }) => {
  const { setCourseId } = useCourse();
  const navigate = useNavigate();
  return (
    <ListItem display="flex">
      <Link
        fontWeight="600"
        onClick={() => {
          navigate(link || '#');
          window.scrollTo(0, 0);

          if (courseId) {
            setCourseId(courseId);
            localStorage.setItem('courseId', courseId);
          }
        }}
        color="rgba(113, 128, 150, 1)"
        _hover={{ color: 'green.600' }}
      >
        {text}
      </Link>
      {isTag && (
        <Text
          as="span"
          bg="#008F94"
          px="0.25rem"
          display="inline-flex"
          alignItems="center"
          color="#fff"
          height="1.25rem"
          borderRadius="0.25rem"
          ml="0.25rem"
          mt="0.25rem"
          fontSize="0.75rem"
        >
          {tagText}
        </Text>
      )}
    </ListItem>
  );
};

export default Footer;
