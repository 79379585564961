// import Carousel from 'react-spring-3d-carousel';
// import { useState, useEffect, useContext } from 'react';
// import { Star } from 'lucide-react';
// import { config } from 'react-spring';
// import axios from 'axios';
// import EnvContext from '../../context/EnvContext';

// export const Carroussel = () => {
//   const [course, setCourse] = useState([]);
//   const { backendUrl } = useContext(EnvContext);
//   const token = localStorage.getItem('token');

//   const headers = {
//     Authorization: `Bearer ${token}`,
//   };

//   useEffect(() => {
//     try {
//       const fetchData = async () => {
//         await axios
//           .get(`${backendUrl}/course/all`, { headers: headers })
//           .then(res => {
//             setCourse(res.data.courses);
//           })
//           .catch(err => {
//             console.log(err);
//           });
//       };
//       fetchData();
//     } catch (err) {
//       console.log(err);
//     }
//   }, []);

//   return (
//     <div className="grid md:grid-cols-3 gap-8">
//       {[
//         course?.slice(0, 3)?.map((item, index) => (
//           <div
//             key={index}
//             className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow"
//           >
//             <img
//               src={`${backendUrl}${item?.banner}`}
//               alt="Course thumbnail"
//               className="w-full h-48 object-cover"
//             />
//             <div className="p-6">
//               <div className="flex items-center justify-between mb-4">
//                 <span className="px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm">
//                   Development
//                 </span>
//                 <div className="flex items-center">
//                   <Star className="w-5 h-5 text-yellow-400 fill-current" />
//                   <span className="ml-1">4.8</span>
//                 </div>
//               </div>
//               <h3 className="text-xl font-semibold mb-2">
//                 Complete Web Development Course
//               </h3>
//               <p className="text-gray-600 mb-4">
//                 Learn web development from scratch to advanced
//               </p>
//               <hr className="mb-4" />
//               <div className="flex items-center justify-between">
//                 <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
//                   Enroll Now
//                 </button>
//               </div>
//             </div>
//           </div>
//         )),
//       ]}
//     </div>
//   );
// };

import Carousel from 'react-spring-3d-carousel';
import { useState, useEffect, useContext } from 'react';
import { Star } from 'lucide-react';
import { config } from 'react-spring';
import axios from 'axios';
import EnvContext from '../../context/EnvContext';

export const Carroussel = () => {
  const [course, setCourse] = useState([]);
  const { backendUrl } = useContext(EnvContext);
  const token = localStorage.getItem('token');

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    try {
      const fetchData = async () => {
        await axios
          .get(`${backendUrl}/course/all`, { headers: headers })
          .then(res => {
            setCourse(res.data.courses);
          })
          .catch(err => {
            console.log(err);
          });
      };
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="grid md:grid-cols-3 gap-8">
      {[
        course?.slice(0, 3)?.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow"
          >
            <img
              src={`${backendUrl}${item?.banner}`}
              alt="Course thumbnail"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <span className="px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm">
                  {item?.category || 'Development'}
                </span>
                <div className="flex items-center">
                  <Star className="w-5 h-5 text-yellow-400 fill-current" />
                  <span className="ml-1">{item?.rating || '4.8'}</span>
                </div>
              </div>
              <h3 className="text-xl font-semibold mb-2">
                {item?.courseName || 'Course Name Not Available'}
              </h3>
              <p className="text-gray-600 mb-4">
                {`Learn ${
                  item?.courseName || 'Course Name Not Available'
                } from scratch to advanced`}
              </p>
              <hr className="mb-4" />
              <div className="flex items-center justify-between">
                <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
                  Enroll Now
                </button>
              </div>
            </div>
          </div>
        )),
      ]}
    </div>
  );
};
