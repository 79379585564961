import React, { useContext, useEffect, useMemo } from 'react';
import EnvContext from '../../../context/EnvContext';
import {
  HStack,
  Image,
  VStack,
  UnorderedList,
  ListItem,
  Text,
  Button,
} from '@chakra-ui/react';
import CourseContext from '../../../context/CourseListContext';
import { Link } from 'react-router-dom';
import ProjectContext from '../../../context/ProjectContext';
import { LeaderBoardOpener } from '../../../components/modals/LeaderBoardOpener';

const CourseShowcase = ({ index }) => {
  const { backendUrl } = useContext(EnvContext);
  const { courses, fetchData, courseId, courseData, getCourse } =
    useContext(CourseContext);

  const { getProjectSubmission } = useContext(ProjectContext);

  const role = useMemo(() => localStorage.getItem('role'), []);

  useEffect(() => {
    if (role === 'admin') fetchData();
    else if (role === 'vendor') getCourse();
  }, [role]);

  // Determine if the course is vendor-specific
  const isVendorCourse = useMemo(
    () => (role === 'vendor' ? !!courseData?.vendor : !!courses[index]),
    [role, courseData, courses, index]
  );

  // Get the appropriate course data based on role
  const currentCourse = useMemo(
    () => (role === 'admin' ? courses[index] : courseData),
    [role, courses, index, courseData]
  );

  // Render a list item with dynamic content
  const renderListItem = (label, value) => (
    <ListItem>
      {label}: {value}
    </ListItem>
  );

  if (index === null) {
    return (
      <Text fontSize="xx-large" fontWeight={700}>
        Select any course
      </Text>
    );
  }

  return (
    <>
      {courseId && (
        <HStack spacing="14" w="100%">
          <VStack>
            {/* Buttons Section */}
            <HStack width="100%" gap="1rem">
              {isVendorCourse && (
                <VStack
                  spacing={2}
                  width="100%"
                  gap="1rem"
                  alignItems="flex-start"
                >
                  <Button colorScheme="orange">
                    <Link to="/admin/update_course">Update Course</Link>
                  </Button>
                  <Button colorScheme="orange">
                    <Link to="/admin/manage_syllabus">Manage Syllabus</Link>
                  </Button>
                </VStack>
              )}

              <VStack
                spacing={2}
                width="100%"
                gap="1rem"
                alignItems="flex-start"
              >
                <Button
                  colorScheme="orange"
                  onClick={() => {
                    localStorage.removeItem('UserId');
                    localStorage.removeItem('ProjectId');
                    localStorage.setItem('history', '/admin/courses');
                    localStorage.setItem('CourseID', courseId);
                    getProjectSubmission(undefined, courseId, undefined);
                  }}
                >
                  Project Submissions
                </Button>
                {isVendorCourse && (
                  <Button colorScheme="orange">
                    <Link to="/admin/manage_reviews">Edit Reviews</Link>
                  </Button>
                )}
              </VStack>
            </HStack>

            {/* Leaderboard Section */}
            <HStack width="100%" gap="1rem">
              <VStack
                spacing={2}
                width="100%"
                gap="1rem"
                alignItems="flex-start"
              >
                <LeaderBoardOpener>
                  <Button colorScheme="orange">User Leaderboard</Button>
                </LeaderBoardOpener>
              </VStack>
            </HStack>

            {/* Course Details */}
            <UnorderedList>
              {renderListItem('Course Name', currentCourse?.courseName)}
              {renderListItem(
                'Certification',
                currentCourse?.certification ? 'Provided' : 'Not Provided'
              )}
              {renderListItem('Language', currentCourse?.language)}
              {renderListItem('No. Of Reviews', currentCourse?.noOfReviews)}
              {renderListItem('Offer Price', currentCourse?.offerPrice)}
              {renderListItem('Original Price', currentCourse?.originalPrice)}
              {renderListItem('Total Rating', currentCourse?.totalRating)}
              <ListItem>
                <Image
                  h="30vh"
                  src={`${backendUrl}${currentCourse?.banner}`}
                  alt="Course Banner"
                />
              </ListItem>
            </UnorderedList>
          </VStack>
        </HStack>
      )}
    </>
  );
};

export default CourseShowcase;
