import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';

//Context
import { toast } from 'react-toastify';
import EnvContext from '../EnvContext';
import AuthContext from '../AuthContext';
import CourseContext from '../CourseListContext';

export const VideoContext = createContext();

const VideoContextProvider = ({ children }) => {
  const token = localStorage.getItem('token');
  const { backendUrl } = useContext(EnvContext);
  const { getAllProducts } = useContext(AuthContext);
  const { fetchData } = useContext(CourseContext);
  const [videoData, setVideoData] = useState([]);

  // Headers
  const headers = {
    authorization: `Bearer ${token}`,
  };

  // Function for get video card
  const handleGetVideo = async () => {
    try {
      const response = await axios.get(`${backendUrl}/course/all`, {
        headers,
      });

      if (response.status === 200) {
        const video = response.data.courses;
        setVideoData(video);
      }
    } catch (error) {
      console.error('Error fetching vendors:', error);
      toast.error('Failed to fetch vendors' || error.response.message);
    }
  };

  const handleAssignedVideo = async data => {
    try {
      const response = await axios.post(`${backendUrl}/vendors/assign`, data, {
        headers,
      });

      handleGetVideo();
      getAllProducts();
      fetchData();

      if (response.status === 201) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log('error:', error);
      toast.error(error.response.data.message);
    }
  };

  const handleDeleteAssignedVideo = async data => {
    try {
      const response = await axios.delete(
        `${backendUrl}/vendors/assigned-videos/${data.userId}/${data.videoId}`,
        { headers }
      );

      handleGetVideo();
      getAllProducts();
      fetchData();

      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error('error in delete assigned video', error);
      toast.error(error.response.data.message);
    }
  };

  // Get assigned video by vendor which is assigned by admin
  const handleGetAssignedVideoByAdmin = async userId => {
    if (!userId) {
      toast.error('Invalid User IDs! Please try again.');
      return;
    }

    const headers = {
      authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    try {
      const response = await axios.get(
        `${backendUrl}/vendors/solp/admin-user/assigned-videos?userId=${userId}`,
        { headers }
      );

      if (response.status === 200) {
        toast.success(
          `${response?.data?.message}` || 'Assigned video Successfully'
        );
      }
    } catch (error) {
      console.error('Error deleting users:', error);

      //Handle error response and fallback to generic message

      const errorMessage =
        error?.status !== 404
          ? error?.message
          : error?.response?.data?.message || 'Unknown error occurred';

      toast.error(`${errorMessage}`);
    }
  };

  return (
    <VideoContext.Provider
      value={{
        handleGetVideo,
        videoData,
        handleAssignedVideo,
        handleDeleteAssignedVideo,
        handleGetAssignedVideoByAdmin,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export default VideoContextProvider;
