import axios from 'axios';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import EnvContext from '../EnvContext';
import { toast } from 'react-toastify';
import { VendorContext } from './VendorContext';

export const VendorContextExtra = createContext();

const VendorContextExtraProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const { handleGetUserByVendor } = useContext(VendorContext);
  const token = localStorage.getItem('token');

  const handleVendorUserDelete = useCallback(
    async (userId, vendorUserId) => {
      if (!userId || !vendorUserId) {
        toast.error('Invalid User IDs and vendorUserId! Please try again.');
        return;
      }
      try {
        const headers = {
          authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          `${backendUrl}/vendors/solp/admin-user/delete?userId=${userId}&vendorUserId=${vendorUserId}`,
          null,
          { headers }
        );

        handleGetUserByVendor(userId);

        if (response.status === 200) {
          toast.success(
            `${response?.data?.message}` || 'User deleted Successfully'
          );
        }
      } catch (error) {
        console.error('Error deleting users.', error);
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          'Error deleting users';
        toast.error(errorMessage);
      }
    },
    [token, backendUrl]
  );

  const handleRevokeCourseAccess = useCallback(
    async (courseId, userId) => {
      if (!userId || !courseId) {
        toast.error('Invalid User IDs and vendorUserId! Please try again.');
        return;
      }
      try {
        const headers = {
          authorization: `Bearer ${token}`,
        };

        const response = await axios.post(
          `${backendUrl}/vendors/solp/admin-user/revoke-course?courseId=${courseId}&userId=${userId}`,
          null,
          { headers }
        );
        console.log('response...............>', response);
      } catch (error) {
        console.log('error...............>', error);
      }
    },
    [token, backendUrl]
  );

  const contextValue = useMemo(
    () => ({ handleVendorUserDelete, handleRevokeCourseAccess }),
    [handleVendorUserDelete, handleRevokeCourseAccess]
  );
  return (
    <VendorContextExtra.Provider value={contextValue}>
      {children}
    </VendorContextExtra.Provider>
  );
};

export default VendorContextExtraProvider;
