import axios from 'axios';
import { createContext, useEffect, useState, useContext } from 'react';
import EnvContext from './EnvContext';
import ContentContext from './ContentContext';
import CourseContext from './CourseListContext';
import { sortTransactionsByDate } from '../Utils/function';
import { useToast } from '@chakra-ui/react';

const AssignmentContext = createContext();

export const AssignmentContextProvider = ({ children }) => {
  const toast = useToast();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { backendUrl } = useContext(EnvContext);
  const { contentId, fetchUserScoreboard, fetchLeaderBoard } =
    useContext(ContentContext);
  const [assignments, setAssignments] = useState([]);
  const { courseId } = useContext(CourseContext);
  const [prevAnsw, setPrevAnsw] = useState([]);
  const [qa, setQa] = useState({});
  const [answers, setAnswers] = useState(null);
  const [quesId, setQuesId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const userId = localStorage.getItem('userId');
  const [queResult, setQueResult] = useState({});
  const [prevAttempts, setPrevAttempts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [prevAttemptsState, setPrevAttemptsState] = useState({}); // Store previous attempts
  const [completedAssignments, setCompletedAssignments] = useState({}); // Track complted status for each element

  const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  });

  useEffect(() => {
    if (quesId) previousAttempts(quesId);
  }, [quesId]);

  const fetchAllAssignments = async value => {
    try {
      const res = await axios.get(
        `${backendUrl}/course/assignment?courseContentId=${value}`,
        {
          headers: getAuthHeaders(),
        }
      );
      setAssignments(res.data.questions);
      // console.log('fetch questions:', res.data.questions);
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  const getQnA = async value => {
    try {
      const res = await axios.get(
        `${backendUrl}/course/assignment/question?questionId=${value}`,
        {
          headers: getAuthHeaders(),
        }
      );
      setQa(res.data.data);
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  const addQnA = async value => {
    const addQnAPromise = axios.post(
      `${backendUrl}/course/assignment/question?courseContentId=${contentId}`,
      value,
      {
        headers: { ...getAuthHeaders(), 'Content-Type': 'multipart/form-data' },
      }
    );

    toast.promise(addQnAPromise, {
      loading: {
        title: 'Adding New Question...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Question added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add question!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      await addQnAPromise;
      fetchAllAssignments(contentId);
      setAnswers(null);
      setSelectedItems(null);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllQuestions = async courseContentId => {
    try {
      const res = await axios.get(
        `${backendUrl}/course/assignment?courseContentId=${courseContentId}`,
        {
          headers: getAuthHeaders(),
        }
      );
      setQuestions(res.data.questions);
    } catch (err) {
      console.log(err);
    }
  };

  const submitAnswer = async answer => {
    const submitAnswerPromise = axios.post(
      `${backendUrl}/user/rs/assignment_submission?questionId=${quesId}`,
      { answer },
      { headers: getAuthHeaders() }
    );

    toast.promise(submitAnswerPromise, {
      loading: {
        title: 'Checking answer...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Answer submitted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to submit the answer!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const response = await submitAnswerPromise;
      const data = response.data.data;
      setQueResult(data);
      fetchUserScoreboard(userId, courseId);
      fetchLeaderBoard();
      previousAttempts(quesId);
      // checkAllAssignmentsSolved(); // Check after submitting an answer
    } catch (error) {
      console.error(error);
    }
  };

  const previousAttempts = async questionId => {
    try {
      const res = await axios.get(
        `${backendUrl}/user/rs/assignment_submission/pre_attempts?questionId=${questionId}`,
        {
          headers: getAuthHeaders(),
        }
      );
      const attempts = res?.data?.data?.prevAttempts;

      // Check if the assignment is completed for this questions
      const isCompleted = attempts.some(attempt => attempt.isCorrectAnswer);

      setPrevAttemptsState(prev => ({
        ...prev,
        [questionId]: attempts,
      }));

      // Update the completed status for the content
      setCompletedAssignments(prev => ({
        ...prev,
        [questionId]: isCompleted,
      }));

      setPrevAttempts(sortTransactionsByDate(res.data.data.prevAttempts));
    } catch (err) {
      console.log(err);
    }
  };

  const resetAssignmentAnswers = async () => {
    try {
      await axios.delete(
        `${backendUrl}/user/rs/assignment_submission?courseId=${courseId}`,
        {
          headers: getAuthHeaders(),
        }
      );
      fetchUserScoreboard(userId, courseId);
      fetchLeaderBoard();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AssignmentContext.Provider
      value={{
        // allAssignmentsSolved ,
        // checkAllAssignmentsSolved,
        fetchAllAssignments,
        addQnA,
        assignments,
        setQuesId,
        quesId,
        getQnA,
        qa,
        setAnswers,
        answers,
        getAllQuestions,
        questions,
        submitAnswer,
        setSelectedAnswers,
        selectedAnswers,
        prevAnsw,
        queResult,
        setQueResult,
        prevAttempts,
        resetAssignmentAnswers,
        previousAttempts,
        setSelectedItems,
        selectedItems,
        setPrevAttempts,
        setCurrentIndex,
        currentIndex,
        completedAssignments,
      }}
    >
      {children}
    </AssignmentContext.Provider>
  );
};

export default AssignmentContext;
