import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

// Context Component
import CartContext from '../context/CartContext';
import AuthContext from '../context/AuthContext';
import CourseContext from '../context/CourseListContext';
import { cryptokey } from '../pages/user/regular/Home';
import CryptoJS from 'crypto-js';
import SkeletonLoader from './SkeletonLoader';
import routesConfig from './RoutesConfig';
import CourseHome from '../pages/admin/course/CourseHome';

const ProtectedRoute = lazy(() => import('./ProtectedRoutes'));

const CourseForm = lazy(() => import('../pages/admin/course/CourseForm'));

const VendorAdminDashboard = lazy(() =>
  import('../components/VendorManagement/VendorAdmin/VendorAdminDashboard')
);

const Header = lazy(() => import('../components/layouts/Header'));

const VendorDashboard = lazy(() =>
  import('../components/VendorManagement/VendorDashboard')
);

const Vendor = lazy(() => import('../components/VendorManagement/Vendor'));

const Layout = lazy(() => import('../components/VendorManagement/Layout'));

const AdminSubHeader = lazy(() =>
  import('../components/layouts/AdminSubHeader')
);

const Footer = lazy(() => import('../components/layouts/Footer'));

export const AllRoutes = () => {
  const location = useLocation();

  const { handleUserData, getAllProducts, userData } = useContext(AuthContext);

  const { courseData, setTopCourses } = useContext(CourseContext);

  const {
    getcart,
    setCartItems,
    cartItems,
    setUnAuthCartItems,
    handeCartlDelete,
  } = React.useContext(CartContext);
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const unAuthItem = localStorage.getItem('unAuthCartItems');
  const [includedCourses, setIncludedCourses] = useState([]);

  useEffect(() => {
    const storedValues = JSON.parse(localStorage.getItem('unAuthCartItems'));
    if (storedValues) {
      setUnAuthCartItems(storedValues);
    }
    const ciphertext = localStorage.getItem('firstFive');
    if (ciphertext) {
      const bytes = CryptoJS.AES.decrypt(ciphertext, cryptokey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setTopCourses(decryptedData);
    }

    if (token) {
      handleUserData();
      if (!unAuthItem) getcart();
    }

    getAllProducts();
  }, []);

  useEffect(() => {
    if (!cartItems) return;
    // Extract course IDs from userData.courses
    const userCourseIds = userData?.courses?.map(course => course?.course?._id);
    const included = cartItems?.filter(item =>
      userCourseIds?.includes(item._id)
    );
    setIncludedCourses(included);
  }, [userData, cartItems]);

  useEffect(() => {
    if (!includedCourses) return;
    if (includedCourses.length > 0) {
      includedCourses.forEach(course => {
        handeCartlDelete(course._id);
      });
    }
  }, [includedCourses]);

  useEffect(() => {
    // console.log('set items hit');
    if (!token) setCartItems('');
  }, [token]);

  return (
    <>
      {location.pathname !== '/video_page' &&
        location.pathname !== '/login' &&
        location.pathname !== '/sign_up' &&
        location.pathname !== '/dashboard' &&
        location.pathname !== '/loading_page/:merchantID' &&
        location.pathname !== '/successful_payment_page' &&
        location.pathname !== '/failed_payment_page' && (
          <Suspense
            fallback={
              <div>
                <SkeletonLoader />
              </div>
            }
          >
            <Header />
          </Suspense>
        )}
      <Suspense
        fallback={
          <div>
            <SkeletonLoader />
          </div>
        }
      >
        <Routes>
          {routesConfig.map(
            ({ path, element, heading, wrapper: Wrapper, extraProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <>
                    {heading && <AdminSubHeader heading={heading} />}
                    {extraProps?.requiresCourseData && !courseData ? (
                      <div>
                        <SkeletonLoader />
                      </div>
                    ) : Wrapper ? (
                      <Wrapper
                        element={React.cloneElement(element, {
                          courseData,
                          ...element.props,
                        })}
                      />
                    ) : (
                      React.cloneElement(element, {
                        courseData,
                        ...element.props,
                      })
                    )}
                  </>
                }
              />
            )
          )}

          <Route
            path="/vendor-managment"
            element={
              <Suspense fallback={<Spinner />}>
                <ProtectedRoute element={<Layout />} />
              </Suspense>
            }
          >
            <Route index element={<Navigate to="dashboard" />} />

            <Route
              path="dashboard"
              element={
                <Suspense fallback={<Spinner />}>
                  <ProtectedRoute
                    element={<VendorDashboard contextType="vendors" />}
                  />
                </Suspense>
              }
            />

            <Route
              path="vendors"
              element={
                <Suspense fallback={<Spinner />}>
                  <ProtectedRoute element={<Vendor contextType="vendors" />} />
                </Suspense>
              }
            />

            <Route
              path="vendor-admin"
              element={
                <Suspense fallback={<Spinner />}>
                  <ProtectedRoute
                    element={<Vendor contextType="vendor-admin" />}
                  />
                </Suspense>
              }
            />

            <Route
              path="video"
              element={
                <Suspense fallback={<Spinner />}>
                  {/* <VendorVideoPage /> */}
                  <ProtectedRoute element={<CourseHome />} />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="/admin"
            element={
              <Suspense fallback={<Spinner />}>
                <ProtectedRoute element={<Layout />} />
              </Suspense>
            }
          >
            {/* Child Route */}
            <Route
              path="vendor-user"
              element={
                <Suspense fallback={<Spinner />}>
                  <ProtectedRoute element={<VendorAdminDashboard />} />
                </Suspense>
              }
            />

            <Route
              path="vendor-user/add-course"
              element={
                <Suspense>
                  <ProtectedRoute element={<CourseForm />} />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </Suspense>
      {/* {location.pathname !== '/bootcamp' ? <Footer /> : null} */}
      {role !== 'admin' &&
        role !== 'vendor' &&
        location.pathname !== '/video_page' &&
        location.pathname !== '/' &&
        location.pathname !== '/sign_up' &&
        location.pathname !== '/generate_otp' &&
        location.pathname !== '/validate_otp' &&
        location.pathname !== '/change_password' &&
        location.pathname !== '/bootcamp' && (
          <>
            <Suspense
              fallback={
                <div>
                  <SkeletonLoader />
                </div>
              }
            >
              <Footer />
            </Suspense>
          </>
        )}
    </>
  );
};
