import { createContext, useContext, useEffect, useState } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
// import toast, { Toaster } from 'react-hot-toast';
import { useToast } from '@chakra-ui/react';

const MentorContext = createContext();

export const MentorContextProvider = ({ children }) => {
  const toast = useToast();

  const [token, setToken] = useState(null);
  const { backendUrl } = useContext(EnvContext);
  const [mentors, setMentors] = useState([]);
  const [mentorName, setMentorName] = useState('');
  const [mentorId, setMentorId] = useState(null);
  const [mentorData, setMentorData] = useState({});

  const fetchMentor = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchMentorPromise = axios.get(
      `${backendUrl}/course/mentor/search?mentorName=${mentorName}`,
      {
        headers: headers,
      }
    );

    toast.promise(fetchMentorPromise, {
      loading: {
        title: 'Loading Mentors...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Mentors loaded successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to load mentors!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await fetchMentorPromise;
      setMentors(res.data.data);
    } catch (err) {
      console.error(err);
      // The error toast is already handled by toast.promise
    }
  };

  const addMentor = async postData => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const addMentorPromise = axios.post(
      `${backendUrl}/course/mentor`,
      postData,
      { headers: headers }
    );

    toast.promise(addMentorPromise, {
      loading: {
        title: 'Mentor List is loading...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Mentor added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add mentor!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      await addMentorPromise;
      fetchMentor();
    } catch (err) {
      console.error('mentor error', err);
    }
  };

  const getMentor = async userId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const getMentorPromise = axios.get(
      `${backendUrl}/course/mentor?mentorId=${mentorId}&userId=${userId}`,
      { headers: headers }
    );

    let loadingToast;
    toast.promise(getMentorPromise, {
      loading: {
        title: 'Mentor List is loading...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Mentor List loaded successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to load Mentor List!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await getMentorPromise;
      setMentorData(res.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const updateMentor = async postData => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const updateMentorPromise = axios.put(
      `${backendUrl}/course/mentor?mentorId=${mentorId}`,
      postData,
      { headers: headers }
    );

    toast.promise(updateMentorPromise, {
      loading: {
        title: 'Updating mentor...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Mentor updated successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to update mentor!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await updateMentorPromise;

      fetchMentor();
    } catch (err) {
      console.error('updateMentor', err);
    }
  };

  return (
    <MentorContext.Provider
      value={{
        fetchMentor,
        setToken,
        mentors,
        setMentorName,
        mentorName,
        setMentorId,
        mentorId,
        addMentor,
        updateMentor,
        getMentor,
        mentorData,
      }}
    >
      {children}
    </MentorContext.Provider>
  );
};

export default MentorContext;
