import { createContext, useEffect, useState, useContext } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

const WishListContext = createContext();

export const WishListContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const [wishListData, seWishListData] = useState([]);
  const userId = localStorage.getItem('UserId');
  const toast = useToast();
  const token = localStorage.getItem('token');

  const handelWishlist = async id => {
    // Show a loading toast
    const loadingToast = toast({
      title: 'Adding course to wishlist...',
      status: 'info',
      isClosable: false,
      position: 'top-right',
      duration: 1000,
      containerStyle: {
        marginTop: '5rem',
      },
    });

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.put(
        `${backendUrl}/user/wishlist/add_item?courseId=${id}`,
        null,
        { headers: headers }
      );
      toast.close(loadingToast);
      // Show success toast after adding to wishlist
      toast({
        title: 'Item added to wishlist successfully!',
        description: res.data.message,
        status: 'success', // Use status for success
        position: 'top-right',
        duration: 1000,
        containerStyle: {
          marginTop: '5rem',
        },
      });
      getWishList(userId);
    } catch (err) {
      toast.close(loadingToast);
      toast({
        title: 'Failed to add course to WishList!',
        description: err.response?.data?.message || 'Something went wrong',
        status: 'error',
        position: 'top-right',
        duration: 1000,
        containerStyle: {
          marginTop: '5rem',
        },
      });
    }
  };

  const getWishList = async id => {
    const token = localStorage.getItem('token');
    let apiUrl = `${backendUrl}/user/wishlist?`;
    if (id) {
      apiUrl += `userId=${id}`;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios.get(apiUrl, { headers: headers }).then(res => {
        seWishListData(res.data.wishlist);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleWishListlDelete = async id => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const deleteWishListPromise = axios.put(
      `${backendUrl}/user/wishlist/remove_item?courseId=${id}`,
      null,
      { headers: headers }
    );

    toast.promise(deleteWishListPromise, {
      loading: {
        title: 'Deleting wishlist...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Wishlist item deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete wishlist item!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await deleteWishListPromise;
      getWishList();
    } catch (err) {
      console.error(err);
    }
  };

  const handelAllWishListDelete = async () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const deleteWishlistPromise = axios.put(
      `${backendUrl}/user/wishlist/remove_all`,
      null,
      {
        headers: headers,
      }
    );

    toast.promise(deleteWishlistPromise, {
      loading: {
        title: 'Deleting all wishlist...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'All wishlist items deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete all wishlist items!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await deleteWishlistPromise;
      getWishList();
      toast({
        title: res.data.message,
        status: 'success',
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
        duration: 1000,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <WishListContext.Provider
      value={{
        handelWishlist,
        getWishList,
        wishListData,
        handleWishListlDelete,
        handelAllWishListDelete,
      }}
    >
      {children}
    </WishListContext.Provider>
  );
};

export default WishListContext;
